.pop-video-container {
  background-color: var(--secondaryColor);
  color: #fff;
  padding: 40px;
  border-radius: 10px;
  /* overflow: hidden; */
}

.pv-git-upload-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0px 80px;
}

.pv-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pv-span-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pv-git-container {
  flex: 1.2;
}

.pv-record-icon-container {
  /* width: 135px; */
  width: 90%;
  height: 100px;
  display: flex;
  margin-top: 45px;
  margin-right: -45px;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

/* .assignment-image{
	background-image: url("../../assets/images/interviewThumb.png");
	background-repeat: no-repeat;
  		background-attachment: fixed;
  background-position: center;
} */
.pv-git-icon-container {
  height: 26px;
  position: absolute;
  top: 25px;
  left: 10px;
}

.pv-youtube-play-button {
  height: 16px;
}

.pv-youtube-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--primaryColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.image-thumbnail {
  position: absolute;
}

.pv-span-container span {
  font-size: 20px;
}

.pv-youtube {
  width: 195px;
  height: 160px;
  background-color: white;
}

.pv-git-icon {
  width: 100%;
  height: 100%;
}

.pv-git-content h2 {
  font-size: 18px;
  margin-top: 8px;
}

.pv-upload-btn-cotainer {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.pv-git-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
}

.pv-textarea-container {
  flex-basis: 100%;
  height: 45px;
  /* background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  padding-left: 40px;
  margin-top: 15px; */
  position: relative;
}

.pv-git-content textarea {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0px 10px 40px;
  /* padding-left: 40px; */
  margin-top: 15px;
  /* overflow: hidden; */
}

.pv-link-ok {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-basis: 100%;
  /* margin-top: 29px; */
  /* text-align: right; */
  margin-top: 20px;
  order: 2;
}

.pv-upload-video-container {
  flex: 1;
  /* margin-top: 45px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pv-upload-video-container h2 {
  font-size: 16px;
}

.pv-upload-video-container p {
  margin: 15px 0px;
}

.pv-uploadbtn-checkmark-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px 0px;
}

.pv-upload-btn-container {
  flex: 0.8;
  flex-shrink: 0;
  max-width: 150px;
}

.pv-video-not-ok-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
  margin-right: 15px;
}

.pv-complete-btn-container {
  max-width: 250px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

.pv-complete-btn {
  padding: 10px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  background-color: var(--primaryColor);
}

.video-container-box {
  display: flex;
}

.first-video-container {
  display: flex;

  flex-direction: column;

  gap: 10px;
}

.second-video-container {
  display: flex;

  flex-direction: column;

  gap: 10px;
}

.video-container-box {
  display: block;
}

.first-video-container {
  gap: 10px;
}

.second-video-container {
  gap: 10px;
}

.red-close-icon {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 30px;
  padding: 2px;
}

@media screen and (max-width: 1100px) {
  .pv-git-container {
    flex-basis: 100%;
  }

  .pv-upload-video-container,
  .pv-upload-btn-container,
  .pv-video-not-ok-container {
    flex-basis: 100%;
  }

  .pv-git-upload-container {
    justify-content: center;
  }

  .pv-upload-video-container {
    margin-top: 0px;
  }

  .pv-record-icon-container {
    /* width: 100%;
    margin-right: 0px; */
  }
}

.both-video-container {
  display: flex;
}

.individual-video-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (max-width: 500px) {
  .pv-git-content h2 {
    font-size: 20px;
    order: 1;
  }

  .pv-record-icon-container {
    /* margin-left: 27px; */
    width: 90%;

    margin: 0 auto;
  }

  .pv-upload-video-container {
    margin-top: 0px;
  }

  .pv-link-ok {
    order: 2;
  }

  .pv-git-content textarea {
    order: 1;
  }

  .pv-git-content {
    gap: 10px 0px;
  }

  .pv-upload-video-container h2 {
    font-size: 20px;
  }

  .pop-video-container {
    padding: 0px;
  }

  .pv-complete-btn-container {
    max-width: 100%;
  }

  .pv-git-container {
    padding: 30px 30px 0px 30px;
  }

  .pv-upload-video-container {
    padding: 0px 30px 0px 30px;
  }

  .pv-complete-btn {
    border-radius: 0px 0px 10px 10px;
  }

  .pv-record-icon-container {
    margin-bottom: 10px;
  }

  .video-container-box {
    padding: 35px;
  }

  .both-video-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
