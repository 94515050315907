.current-openings-heading span {
	border-bottom: solid 2px var(--primaryColor);
}

.dcs-container {
	position: relative;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	margin: 30px 0px 70px 0px;
}

.dcs-container > * {
	flex: 1;
}

/* Shapes */

.sp-title-container {
	width: 100%;
	height: 100%;
	padding: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	text-align: right;
}

.sp-title-java {
	color: #fff;
	font-weight: 800;
	font-size: 50px;
}

.sp-title-html {
	font-size: 40px;
	text-align: right;
	max-width: 95px;
	font-weight: 800;
	color: #fff;
}

.sp-title-linux {
	font-size: 37px;
	text-align: right;
	max-width: 114px;
	font-weight: 800;
	color: #fff;
}

.sp-title-js {
	color: #fff;
	font-weight: 800;
	font-size: 80px;
}

.leftArrow {
	position: absolute;
	top: 40%;
	left: -50px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ArrowStyle {
	transition: all 0.3s ease;
	font-size: 1.6em;
	background-color: var(--primaryColor);
	color: #fff;
	box-shadow: 0 0 2px 0px #333;
	border-radius: 50%;
	border: none;
	padding: 0;
	width: 50px;
	height: 50px;
	min-width: 50px;
	line-height: 50px;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	cursor: pointer;
	outline: none;
}

.rightArrow {
	position: absolute;
	top: 40%;
	right: -50px;
	cursor: pointer;
}

.rec-slider-container {
	margin: 0px !important;
}

.rec-dot {
	background-color: #252a4fc5 !important;
	/* box-shadow: 0 0 1px 3px var(--secondaryColor) !important; */
}

.rec-dot__active {
	/* background-color:#252a4fc5 !important; */
	box-shadow: 0 0 1px 3px var(--secondaryColor) !important;
}

.prevArrow img {
	width: 30px;
	height: 30px;
}

.nextArrow img {
	width: 30px;
	height: 30px;
	transform: rotate(180deg);
}
/* Shapes */

.no-job-opening-container {
	grid-column: 1 / -1;
	padding: 120px 0px;
	text-align: center;
	border: solid 2px var(--secondaryColor);
	border-radius: 10px;
}

.no-job-opening-container-text {
	line-height: 1.2;
}

@media screen and (max-width: 1200px) {
	/* .dcs-container > * {
      flex-basis: calc(50% - 60px);  
   } */
	.dcs-container {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 600px) {
	.current-openings-heading span {
		font-size: 20px;
	}

	.dcs-container {
		gap: 10px;
		margin: 35px 0px 70px 0px;
	}

	.sp-title-java {
		font-size: 40px;
	}

	.sp-title-html {
		font-size: 30px;
		max-width: 70px;
	}

	.sp-title-linux {
		font-size: 27px;
	}

	.sp-title-js {
		font-size: 70px;
	}
}

@media screen and (max-width: 600px) {
	.dcs-container {
		grid-template-columns: 1fr;
	}
}
