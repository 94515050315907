* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:root {
	--primaryColor: #b93631;
	--secondaryColor: #252a4f;
}

@font-face {
	font-family: "Jaldi";

	src: url(assets/fonts/Jaldi-Regular.ttf);
}

@font-face {
	font-family: "Jaldi";

	src: url(assets/fonts/Jaldi-Regular.ttf);
}

body {
	font-family: "Jaldi", sans-serif;
	color: var(--secondaryColor);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	line-height: 1;
}

img {
	max-width: 100%;
}

li {
	list-style-type: none;
}

a {
	display: block;
	text-decoration: none;
}

button,
input {
	font-family: "Jaldi", sans-serif;
}

button {
	cursor: pointer;
}

.main-container {
	max-width: 80%;
	margin: 0 auto;
}

.logo {
	max-width: 160px;
	display: flex;
	align-items: center;
}

input[type="file"] {
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	cursor: pointer;
}

input[type="file"] {
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	cursor: pointer;
	position: absolute;
}

.inheritLi {
	list-style-type: inherit;
}

@media screen and (min-width: 1200px) {
	.main-container {
		max-width: 1100px;
	}
}

@media screen and (max-width: 600px) {
	.main-container {
		max-width: 90%;
	}

	.logo {
		max-width: 120px;
	}
}
