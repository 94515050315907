.footer-container {
}

.footer-navigation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.footer-logo-container {
  max-width: 250px;
  margin-bottom: 10px;
  cursor: pointer;
}

.footer-navigation-links ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 50px;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.footer-navigation-links li {
  cursor: pointer;
}

.footer-additional-container {
  border-top: solid 1px #ececec;
  color: #9ba0b1;
}

.footer-additional-content ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 0px;
  font-size: 12px;
}

.privary-policy-link {
  color: #252a4f;
  /* text-decoration: underline; */
  cursor: pointer;
  display: flex;
  gap: 5px;
}

@media screen and (max-width: 600px) {
  .footer-navigation-links ul > * {
    flex: 1;
    flex-basis: 40%;
  }

  .footer-additional-content ul {
    justify-content: flex-start;
    gap: 0px 48px;
  }

  .footer-additional-content ul > {
    order: 1;
  }

  .privary-policy-link {
    order: -1;
    flex-basis: 100%;
  }

  .footer-logo-container {
    max-width: 200px;
  }
}
