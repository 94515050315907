/* .error-modal-frame {
  width: 50%;
  margin: 0 auto;
} */

.error-modal {
  width: 40%;
  height: 270px;
  background: #fff;
  border-radius: 15px;
  margin: 0 auto;
  -webkit-animation: show-modal 0.7s ease-in-out;
  animation: show-modal 0.7s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.error-modal.hide {
  -webkit-animation: hide-modal 0.6s ease-in-out both;
  animation: hide-modal 0.6s ease-in-out both;
}

.error-modal img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.error-modal .errorTitle {
  color: var(--primaryColorVariant);
  font-weight: bold;
}
.error-modal p {
  font-size: 20px;
  text-align: center;
  padding: 0 10px;
  /* width: 80%; */
}

.error-modal-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
@-webkit-keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}

@media screen and (max-width: 1000px) {
  .error-modal {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .error-modal {
    width: 90%;
  }
}
