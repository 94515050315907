.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin-top: -200px;
  margin-left: -200px;
  border-radius: 2px;
  overflow: hidden;
  color: #415868;
  font-family: "Open Sans", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal {
  position: absolute;
  width: 280px;
  height: 210px;
  top: 95px;
  left: 60px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.4);
  text-align: center;
  overflow: hidden;
  -webkit-animation: show-modal 0.7s ease-in-out;
  animation: show-modal 0.7s ease-in-out;
}

.modal.hide {
  -webkit-animation: hide-modal 0.6s ease-in-out both;
  animation: hide-modal 0.6s ease-in-out both;
}

.modal .title {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin: 50px 0 5px 0;
}

.modal p {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  margin: 0;
  padding: 0 30px;
}

.modal .button {
  height: 40px;
  border-radius: 2px;
  margin: 20px auto;
  width: 30%;
  background: var(--primaryColor);
  color: #fff;
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

@-webkit-keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}