.welcome-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  min-height: 280px;
}

.welcome-login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 15px 0px rgba(163, 162, 162, 0.75);
  padding: 50px 40px;
  border-radius: 5px 0px 0px 5px;
}

.welcome-login-container h2 {
  color: var(--primaryColor);
  font-size: 21px;
  margin-bottom: 50px;
  margin-top: 10px;
}

.welcome-login-container p {
  font-size: 18px;
  margin-bottom: 10px;
}

.welcome-login-btn {
  cursor: pointer;
}

.welcome-login-btn span {
  font-size: 18px;
  font-weight: 700;
  border-bottom: solid 2px var(--primaryColor);
}

.welcome-sign-up-container {
  flex: 2.5;
  display: flex;
  color: #cacaca;
  position: relative;
}

.sign-up-content {
  flex-basis: 75%;
  background-color: var(--secondaryColor);
  padding: 60px 30px;
  position: relative;
}

.laptop-img-container {
  flex: 1;
  position: absolute;
  right: 0px;
  max-width: 400px;
  transform: translate(0px, -9px) scale(1.2);
}

.sign-up-content p {
  font-size: 18px;
  max-width: 260px;
  margin-bottom: 10px;
}

.welcome-sign-up-btn {
  background-color: var(--primaryColor);
  border-radius: 10px;
  max-width: 170px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4f4f4;
  font-weight: bold;
  cursor: pointer;
}

.ws-continue-btn {
  font-size: 18px;
  font-weight: 700;
  border-bottom: solid 2px var(--primaryColor);
  color: #fff;
  cursor: pointer;
}

.welcome-sign-up-btn span {
  font-size: 18px;
}

.hi-user-text {
  font-size: 22px !important;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .welcome-container > * {
    flex-basis: 100%;
  }

  .welcome-container {
    position: relative;
  }

  .welcome-login-container {
    padding: 40px;
    border-radius: 10px;
  }

  .welcome-login-container h2 {
    margin-bottom: 270px;
  }

  .sign-up-content {
    flex-basis: 100%;
    position: absolute;
    width: 100%;
    padding: 40px 30px;
    transform: translate(0px, -355px);
  }

  .laptop-img-container {
    max-width: 360px;
    transform: translate(0px, -395px) scale(1);
  }
}

@media screen and (max-width: 800px) {
  .welcome-container {
    margin-bottom: 10px;
  }

  .laptop-img-container {
    right: -20px;
    transform: translate(0px, -395px) scale(0.9);
  }
}

@media screen and (max-width: 700px) {
  .welcome-login-container {
    padding: 30px;
  }

  .welcome-login-container h2 {
    font-size: 20px;
  }

  .welcome-login-container p {
    font-size: 16px;
  }

  .sign-up-content {
    transform: translate(0px, -345px);
  }

  .sign-up-content p {
    font-size: 16px;
    max-width: 250px;
    margin-bottom: 30px;
  }

  /* .welcome-sign-up-btn {
      width: 112px;
   } */

  .welcome-sign-up-btn span {
    font-size: 14px;
  }

  .ws-continue-btn {
    font-size: 14px;
  }

  .welcome-login-btn span {
    font-size: 14px;
  }

  .laptop-img-container {
    right: -60px;
    transform: translate(0px, -360px) scale(0.7);
  }
}

@media screen and (max-width: 500px) {
  .welcome-login-container h2 {
    margin-bottom: 250px;
  }

  .sign-up-content {
    transform: translate(0px, -320px);
  }

  .laptop-img-container {
    right: -90px;
    transform: translate(0px, -330px) scale(0.5);
  }

  .welcome-sign-up-btn {
    width: 120px;
  }
}

@media screen and (max-width: 350px) {
  .sign-up-content {
    padding: 30px;
  }

  .laptop-img-container {
    right: -110px;
    transform: translate(0px, -330px) scale(0.45);
  }
}
