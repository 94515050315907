
.modal-container {
   position: fixed;
   z-index: 1100;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   display: none;
   justify-content: center;
   align-items: center;
}
