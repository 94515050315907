.header-notification-dropdown-container {
  display: none;
  flex-direction: column;
  position: absolute;
  background-color: white;
  z-index: 10;
  top: 30px;
  right: 0;
  margin-top: 10px;
  width: 250px;
  height: 250px;
  min-height: 150px;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px rgb(163 162 162 / 75%);
}
.header-notification-dropdown-active {
  display: flex;
}
.header-new-notification-shown {
  top: 1px;
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primaryColor);
  border-radius: 50%;
  right: -2px;
  color: #fff;
}
.header-notification-box {
  height: 80%;
  padding: 0px 0px 5px;
  position: relative;
  scrollbar-width: none;
  text-align: center;
}
.header-notification-heading-container {
  background: #252a4f;
  border-radius: 5px 5px 0 0;
  padding: 15px 35px 15px 20px;
  position: relative;
}
.header-notification-heading-container h6 {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}
.header-notification-message-container-item {
  position: relative;
  padding: 20px 10px;
  border-bottom: 1px solid #dad8d8;
  display: flex;
  align-items: flex-start;
  gap: 0px 10px;
}
.header-notification-message-container-item .notification-heads {
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.02em;
  margin-bottom: 3px;
}
.notification-cricle {
  width: 8px;
  height: 8px;
  background-color: var(--primaryColor);
  border-radius: 50%;
  margin-top: 3px;
}
.header-notification-message-container-item .notification-message {
  font-size: 15px;
  color: var(--secondaryColor);
  font-weight: 600;
  text-align: left;
}
.header-notification-message-container-item.notification-read {
  background-color: #f7f7f7;
}

.notification-icon-animation {
  animation: ring 1.5s ease;
}

.notification-application {
  position: absolute;
  font-size: 14px;
  bottom: 1px;
  right: 6px;
  color: #9a9a9a;
}

@keyframes ring {
  0% {
    transform: rotate(35deg);
  }
  12.5% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(25deg);
  }
  37.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(15deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 400px) {
  .header-notification-dropdown-container {
    right: -60px;
  }
}
