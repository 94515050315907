.pageNotFound-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 40px 0px;
}

.pageNotFound-container h5 {
	font-size: 250px;
	color: var(--secondaryColor);
	font-weight: bold;
}

.pageNotFound-container p {
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 20px;
}
.pageNotFound-container span {
	line-height: 1.2;
	font-size: 18px;
	max-width: 50%;
	text-align: center;
	margin-bottom: 20px;
}
.image-container {
	width: 50%;
}
@media screen and (max-width: 600px) {
	.image-container {
		width: 80%;
	}
	.pageNotFound-container span {
		max-width: 100%;
	}

	.pageNotFound-container h5 {
		font-size: 220px;
	}
	.pageNotFound-container p {
		font-size: 30px;
	}
}
