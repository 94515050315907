.interview-process-info-graphics-home-container {
  margin: 85px 0 60px 0;
  border-radius: 10px;
}
.process-step-message-container {
  display: flex;
  gap: 5px;
}

@media screen and (max-width: 900px) {
  .interview-process-info-graphics-home-container {
    margin: 50px 0;
    border-radius: 10px;
  }
}
