.mobile-navigation-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  box-shadow: -4px 4px 25px 0px #1010101a;
  background-color: #fff;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  width: 300px;
  visibility: hidden;
}

.show-mobile-navigation-container {
  visibility: visible !important;
}

.mobile-navigation-container > * {
  flex-basis: 100%;
}

.mn-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #efebeb;
}

.mn-logo-container {
  cursor: pointer;
  max-width: 200px;
}

.mn-cross-icon-container {
  cursor: pointer;
}

.mn-links-conatiner ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mn-links-conatiner ul li {
  color: var(--secondaryColor);
  font-size: 14px;
  margin-bottom: 20px;
  font-weight: 600;
  cursor: pointer;
}

.mn-footer-container {
  align-self: flex-end;
}

@media screen and (max-width: 400px) {
  .mobile-navigation-container {
    width: 90%;
  }
}


.body-background-container {
  background: rgb(0 0 0 / 70%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
}
