.dc-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 15px 0px rgb(163 162 162 / 75%);
  min-width: 180px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  /* width: 95%;
  margin: 10px 0; */
}

.dc-image-container {
  flex: 2;
  display: flex;
}

.dc-image {
  flex-basis: 100%;
}

.dc-content {
  flex: 1;
  padding: 20px;
}

.dc-content h3 {
  margin-bottom: 5px;
}

.dc-content p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  color: #6f728a;
}
.new-job-available-para {
  font-size: 14px;

  background-color: var(--primaryColor);
  color: white;
  padding: 0px 8px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 8px;
}

.applied-text {
  /* color: white;
  padding: 0px 8px;
  background-color: #6f728a;
  display: inline-block;
  border-radius: 5px;
  margin-top: 8px; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
  background-color: #f2f2f4;
  border-radius: 4px;
  width: fit-content;
  padding: 5px 8px;
  margin-bottom: 10px;
}
.applied-font {
  font-size: 14px;
}
/* ==== */
