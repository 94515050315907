
.google-sign-icon {
   width: 35px;
   padding: 8px;
   background-color: #fff;
   margin-right: 10px;
}

.google-sign-btn {
   justify-content: flex-start;
   align-items: center;
   padding: 5px;
   border-radius: 5px;
}

@media screen and (max-width: 600px) {
   
   .google-sign-icon {
      width: 24px;
      padding: 5px;
   } 

}