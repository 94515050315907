.loader-container {
  padding: 20px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #707070;
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader-container p {
  color: var(--primaryColor);
}

.loading-img {
  animation: spin 2.5s ease-out infinite;
  transform-origin: center;
  transform: rotate(0deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
