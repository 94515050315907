.pop-up-container {
  width: 70%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #00000029;
  border-radius: 20px;
}
.inner {
  position: relative;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.highlight {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #252a4f;
}

.para {
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  line-height: 1.2;
}

.final {
  display: flex;
  align-items: center;
  gap: 10px;
}
.final span {
  font-size: 24px;
}

.icon-check-mark {
  height: 24px;
  width: 24px;
}
.applied-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.close-icon {
  width: 24px;
  align-self: flex-end;
  cursor: pointer;
  position: absolute;
}

@-webkit-keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes show-modal {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes hide-modal {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}
@media screen and (max-width: 600px) {
  .final span {
    font-size: 20px;
  }
  .para {
    font-size: 16px;
  }
  .highlight {
    font-size: 22px;
  }
  .inner {
    padding: 30px;
  }
  .applied-container .highlight{
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .pop-up-container {
    width: 92%;
  }
}