.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.logo-img-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
}
.logo-new {
  max-width: 240px !important;
}

.header-right-container {
  display: flex;
  align-items: center;
  gap: 35px;
}

.header-left-container {
  /* width: 100%; */
}
.header-user-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}

.header-user-icon-container {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* overflow: hidden; */
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}

.header-user-dropdown-container {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  z-index: 10;
  top: 30px;
  right: 0;
  margin-top: 10px;
  width: 250px;
  min-height: 150px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px 0px rgb(163 162 162 / 75%);
}

.header-user-dropdown-container p {
  margin-bottom: 10px;
}

.header-user-dropdown-active {
  display: flex;
}

.menu-container {
  display: flex;
  cursor: pointer;
}

.header-user-profile-container {
  display: flex;
  gap: 7px;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.header-user-profile-container img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.header-user-profile-container h4 {
  font-size: 18px;
  font-weight: 600;
}

.header-user-profile-container p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}

.refLogo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .logo-img-container {
    max-width: 200px;
    gap: 10px;
  }

  .header-right-container {
    gap: 15px;
  }
  .logo-new {
    max-width: 160px !important;
  }
}
@media screen and (max-width: 350px) {
  .header-user-dropdown-container {
    right: -30px;
  }
}

.teknologo {
  width: 60px;
  margin-left: 15px;
}

.referral-logos-container {
  width: 80%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.slash-divide {
  background-color: gray;
  margin-left: 2px;
  display: block;
  width: 1px;
  height: 40px;
}
