.header-popup-cont {
  background: #fff;
  width: 80vw;
  height: 80vh;
  padding: 30px 40px;
  position: relative;
  overflow: scroll;
}
.refclose-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.tekno-content {
  margin-top: 17px;
}
