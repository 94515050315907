.wtp-bg-img-container {
  min-height: 500px;
  background-image: url("../../assets/images/why-tenkopoint-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.wtp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
  color: #fff;
}

.wtp-container h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.wtp-info {
  display: flex;
  gap: 10px;
  max-width: 420px;
}

.wtp-info hr {
  width: 40px;
  height: 0.5px;
  border: none;
  background-color: #fff;
  margin-top: 8px;
}

.wtp-info p {
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 20px;
}

.wtp-sign-in-btn {
  width: 140px;
  height: 40px;
  border: solid 2px #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wtp-sign-in-btn span {
  font-size: 18px;
}

.wtp-referral-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  color: #fff;
  gap: 20px;
}

.wtp-referral-container h2 {
  border-bottom: 2px solid var(--primaryColor);
}

.ref-image-container {
  flex: 0.2;
  background: #fff;
  border-radius: 15px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.referral-details-container {
  display: flex;
  color: #fff;
  gap: 20px;
  width: 100%;
}

.referral-details {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  align-items: center;
  background: #fff;
  border-radius: 15px;
  padding: 25px 40px;
  color: var(--secondaryColor);
}

.referral-details p {
  display: flex;
  flex-direction: column;
}

.referral-bold {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .wtp-container {
    align-items: center;
  }

  .wtp-info {
    flex-direction: column;
    align-items: center;
    max-width: 220px;
  }

  .wtp-info hr {
    margin: 0 0 10px 0px;
  }

  .wtp-info p {
    text-align: center;
  }

  .wtp-container h2 {
    font-size: 18px;
  }

  .wtp-info p {
    font-size: 14px;
  }

  .wtp-sign-in-btn span {
    font-size: 14px;
  }

  .referral-details-container {
    flex-direction: column;
    align-items: center;
  }

  .ref-image-container {
    width: fit-content;
  }
  .referral-details {
    grid-template-columns: 1fr;
    padding: 25px;
  }
}
