
.button {
   border: none;
   outline: none;
   padding: 10px;
   width: 100%;
   height: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 10px;
   font-size: 14px;
   color: #fff;
   font-weight: 600;
   cursor: pointer;
}

.button:disabled {
   opacity: 0.5;
}

@media screen and (max-width: 600px) {
   
   .button {
      font-size: 12px;
      height: 40px;
   }

}