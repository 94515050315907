.bg,
.sec-bg {
  min-height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.bg {
  background-color: var(--secondaryColor);
}

.sec-bg {
  background-color: var(--primaryColor);
}

.cricle,
.sec-cricle {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: absolute;
  z-index: 1;
}

.cricle {
  background-color: var(--primaryColor);
}

.sec-cricle {
  background-color: var(--secondaryColor);
}

.square,
.sec-square {
  width: 160px;
  height: 160px;
  position: absolute;
  z-index: 2;
  border: solid 10px#F2F2F2;
  border-radius: 5px;
  transform: translateX(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.square::before,
.sec-square::before {
  position: absolute;
  content: "";
  width: 200px;
  height: 200px;
  border-radius: 0 0 50% 50%;
  transform: translateX(-10px);
}

.square::before {
  border: solid 25px var(--secondaryColor);
}

.sec-square::before {
  border: solid 25px var(--primaryColor);
}

.square::after,
.sec-square::after {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  transform: translate(-75px, 30px);
}

.square::after {
  background-color: var(--secondaryColor);
}

.sec-square::after {
  background-color: var(--primaryColor);
}

@media screen and (max-width: 1200px) {
  .bg,
  .sec-bg {
    min-height: 230px;
  }
}

@media screen and (max-width: 600px) {
  .bg,
  .sec-bg {
    min-height: 200px;
  }

  .cricle,
  .sec-cricle,
  .square::before,
  .sec-square::before {
    width: 160px;
    height: 160px;
  }

  .square,
  .sec-square {
    width: 140px;
    height: 140px;
  }

  .square::after,
  .sec-square::after {
    width: 25px;
    height: 25px;
    transform: translate(-65px, 30px);
  }
}

.shape-title {
  color: #fff;
  font-weight: 800;
}

.sp-small {
  font-size: 40px;
}

.sp-medium {
  font-size: 28px;
}

.sp-big {
  font-size: 22px;
  text-align: right;
  max-width: 100%;
}
