.interview-process-container {
  display: flex;
  flex-wrap: wrap;
}

.process-title-container {
  flex: 1.5 1;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
  gap: 10px;
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
}

.process-title-container > * {
  flex: 1;
}

.process-title h2 {
  font-size: 28px;
}

.interview-process-steps-container {
  flex: 2;
  background-color: var(--secondaryColor);
  color: #fff;
  padding: 35px;
  border-radius: 0px 10px 10px 0px;
}

.process-interview-icon {
  height: 150px;
}

.interview-process-steps-container ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.interview-process-steps-container h2 {
  font-size: 22px;
  margin-bottom: 20px;
}

.process-step-icon-container {
  display: flex;
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.process-step {
  flex: 1;
}

.process-step p {
  max-width: 100px;
}

/* Shape Start */

.default-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.default-cricle {
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: absolute;
  z-index: 1;
  background-color: var(--primaryColor);
}

.default-square {
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 2;
  border: solid 8px #F2F2F2;
  border-radius: 5px;
  transform: translateX(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-square::before {
  position: absolute;
  content: "";
  width: 140px;
  height: 140px;
  border-radius: 0 0 50% 50%;
  transform: translateX(-10px);
  border: solid 20px #fff;
}

.default-square::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(-56px, 25px);
  background-color: var(--secondaryColor);
}

.process-interview-shape {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.pi-shape-title {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
}

/* Shape End */

@media screen and (max-width: 1100px) {
  .interview-process-container > * {
    flex-basis: 100%;
  }

  .process-title-container {
    order: 1;
    border-radius: 0px 0px 10px 10px;
  }

  .interview-process-steps-container {
    order: 0;
    border-radius: 10px 10px 0px 0px;
  }

  .process-interview-icon {
   display: none;
 }

  .process-title h2 {
    font-size: 24px;
  }

  .process-step {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 600px) {
  .process-step p {
    font-size: 14px;
  }

  .process-title h2 {
    font-size: 18px;
  }
}
