.popup-traning-video-container {
  width: 60%;
  height: 60%;
  background-color: #fff;
  position: relative;
  margin-top: 40px;
}

.pt-video-iframe-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.pt-video-iframe-container iframe {
  flex: 1;
  width: 100%;
  height: 100%;
}
.pt-edit-icon-container {
  min-width: 40px;
  min-height: 38px;
  top: 15px;
  right: 70px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pt-close-icon-container {
  top: 15px;
  width: 40px;
  height: 38px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;

  box-shadow: 2px 2px 3px #00000033;
}

.popup-update-button {
  width: 100px;
  height: 50px;
  top: -70px;
  position: absolute;
  right: 0px;
}

.pt-user-videos-container {
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;
  width: 20%;
}

.pt-user-video-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pt-user-video-container img {
  background-color: #f5f5f5;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 5px;
}

.pt-user-video-container p {
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .pt-video-iframe-container {
    flex-direction: column;
  }

  .pt-video-iframe-container > * {
    order: 1;
  }

  .pt-user-videos-container {
    flex-direction: row;
    width: 100%;
    order: 2;
  }

  .pt-user-video-container {
    padding: 20px 0px;
  }

  .pt-user-video-container {
    padding: 10px 0;
  }
}

.pt-user-videos-container {
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;
  width: 20%;
}

.pt-user-video-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pt-user-video-container img {
  background-color: #f5f5f5;
  width: 60px;
  height: 60px;
  padding: 10px;
  border-radius: 5px;
}

.pt-user-video-container p {
  margin-top: 10px;
}

.video-con-selected {
  background-color: #d6d5d5;
}

@media screen and (max-width: 1000px) {
  .pt-video-iframe-container {
    flex-direction: column;
  }

  .pt-video-iframe-container > * {
    order: 1;
  }

  .pt-user-videos-container {
    flex-direction: row;
    width: 100%;
    order: 2;
  }

  .pt-user-video-container {
    padding: 20px 0px;
  }

  .pt-user-video-container {
    padding: 10px 0;
  }
}

@media screen and (max-width: 600px) {
  .popup-traning-video-container {
    height: 60%;
  }

  .pt-user-video-container img {
    width: 30px;
    height: 30px;
    padding: 5px;
  }

  .pt-user-video-container p {
    font-size: 14px;
  }
}
