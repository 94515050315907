.howToHirestarContainer {
  background-color: var(--secondaryColor);
  color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 20px;
  margin: 80px 0 40px 0;
  border-radius: 10px;
}

.how-to-text-container {
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgb(163 162 162 / 75%);
  padding: 50px 40px;
  border-radius: 0px 5px 5px 0px;
  color: var(--secondaryColor);
}

.laptop-video-container {
  position: relative;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.laptop-img {
  width: 80%;
}

.youtube-video {
  position: absolute;
  cursor: pointer;
  top: 5%;
  left: 21.5%;
  width: 57%;
  height: 80%;
}

@media screen and (max-width: 1000px) {
  .howToHirestarContainer {
    grid-template-columns: 1fr;
    font-size: 20px;
    gap: 20px;
  }

  .how-to-text-container {
    border-radius: 0px 0px 5px 5px;
  }

  @media screen and (max-width: 720px) {
    /* .youtube-video {
      position: absolute;
      top: 14%;
      left: 20%;
      width: 63%;
      height: 64%;
    } */
  }

  @media screen and (max-width: 520px) {
    .howToHirestarContainer {
      font-size: 16px;
    }
    .how-to-text-container {
      padding: 20px;
    }
  }

  @media screen and (max-width: 320px) {
    .howToHirestarContainer {
      font-size: 14px;
    }
  }
}
