.share-container {
  max-width: 400px;
  width: 90%;
  border-radius: 10px;
  background: #fff;
  opacity: 1;
  padding: 20px;
  text-align: center;
  position: relative;
  animation: show-modal 0.7s ease-in-out;
  margin: 0px 10p;
}

.share-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 40px;
}

.icon-container {
  display: flex;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  gap: 10%;
}

.share-icon-container {
  border-radius: 50%;
  border: 1px solid #eaeaea;
  align-items: center;
  width: 10%;
  height: 10%;
}

.share-icon-container img {
  padding: 5px;
}

.share-icon-container:hover {
  transition: all 0.5s ease-in-out;
  transform: scale(1.07);
  box-shadow: 0px 4px 25px 0px #252a4f33;
}

.url-generate-containerBox {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.url-generate-containerBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-top: 30px;
  border-top: 1px solid #0000001a;
  position: relative;
}

.url-containerBox {
  display: flex;
  margin-top: 5%;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  position: relative;
}

.url-containerBox-link {
  border-left: 1px solid #dbdbdb;
  padding: 5px;
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.url-btnBox {
  background: #252a4f;
  border-radius: 0px 10px 10px 0px;
  border: none;
  flex: 0.2;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.url-btn-imageBox {
  width: 20px;
  align-self: center;
}

.sharepopup-shareIconBox {
  width: 30px;
  height: 30px;
  padding: 5px;
  align-self: center;
}

.sp-tooltip {
  background: red;
}

.sp-down-arr-tooltip {
}

.share-popup-separator {
  position: absolute;
  top: -8px;
  padding: 0 5px;
  left: 46%;
  background-color: white;
  color: #c0b4b4;
}

.copy-link-class {
  letter-spacing: 0px;
  color: #252a4f;
  opacity: 1;
  margin-top: 28px;
}

.tool-tip-container {
  position: absolute;
  right: 10px;
  top: 0;
  background: var(--secondaryColor);
  color: #fff;
  padding: 5px;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out .5s;
}

.show-tool-tip {
  opacity: 1;
  visibility: visible;
  top: -30px;
  right: 10px;
}

@media screen and (max-width: 526px) {
  /* .url-containerBox {
      min-width: 100%;
    } */
  .url-generate-containerBox {
    min-width: 20%;
  }
  .url-containerBox-link {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
